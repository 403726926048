
export default {
  name: "Sindex",
  layout: "main-head",
  data() {
    return {
      tabList: [],
      tabId: "All",
      list:[],
      params:{
        pageNum:1,
        pageSize:600
      },
      loading:false,
      islist:false,
      isLast:false,
      countrylist:[]
    };
  },
  head() {
    return this.setHead();
  },
  async asyncData({ $api, cookies, ...context }) {
        const [response, categoryR,country] = await Promise.all([
            $api.home.findCategoryFirstLetter(),
            $api.home.categoryFirstLetterQuery({
              pageNum:1,
              pageSize:600,
              letter:''
            }),
            $api.common.getCountryList()
        ])
        const res = response?.result || [];
        const resR = categoryR?.result?.list || [];
        let list = [{tabName:'All',tabValue:'All'}];

        let clist = country?.result || [];
        let countrylist = [];
        countrylist.push(clist.find(item=>{ return item.countryCode == 'US'}))
        clist = clist.filter(item=>{
          return item.mRealmName != 'https://m.yfn.com'
        })
        countrylist.push(...clist)
        if(res?.length!=0){
          res?.map((item) => {
            list.push({
              tabName: item,
              tabValue: item,
            });
          });
        }else{
          list = []
        }
        return {
            tabList: list,
            list:resR,
            countrylist:countrylist
        }
    },
  mounted() {
    // this.getCategoryFirstLetter();
    // this.getfirstLetterQuery()
  },
  methods: {
    handleTab(){
      this.params.pageNum = 1
      this.list = []
      this.isLast = false
      this.getfirstLetterQuery()
    },
    getList(){
      if(!this.islist&&!this.isLast){
        this.islist = true
        this.params.pageNum++
        this.getfirstLetterQuery()
      }
    },
    getfirstLetterQuery(){
      this.$api.home.categoryFirstLetterQuery({...this.params,letter:this.tabId}).then((res) => {
        res.result?.list?.length ? this.list.push(...res.result.list) : this.isLast = true;
        this.loading = false;
        this.islist = false;
      });
    },
    getCategoryFirstLetter() {
      this.tabList = [{tabName:'All',tabValue:''}];
      this.$api.home.findCategoryFirstLetter().then((res) => {
        res?.result?.map((item) => {
          this.tabList.push({
            tabName: item,
            tabValue: item,
          });
        });
      });
    },
    // SEO HEAD
    setHead() {
      const head = this.$ctxApi.seoHead.initial({
        title: this.$translate("Site Index"),
        keywords: this.$translate("Site Index"),
        description: this.$translate(
          "Explore the YFN index to easily find the countries site and categories you are looking for."
        ),
        image: "https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png",
      });
      return {
        ...head,
      };
    },
  },
};
